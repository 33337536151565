<script setup lang="ts">
import { useSchoolStore } from "@/stores/SchoolStore";
import { computed, inject, ref } from "vue";
import SchoolForm from "../SchoolForm.vue";
import Button from "primevue/button";
import Dialog from "primevue/dialog";
import Divider from "primevue/divider";
import type { School } from "@/api/prisma-interfaces";
import type { SchoolDto } from "@/services/SchoolService";
import { useConfirm } from "primevue/useconfirm";
import { useFeedbackToast } from "@/composables/useFeedbackToast";
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";
import { RssIcon } from "@heroicons/vue/20/solid";
import * as config from "@/config";

const confirm = useConfirm();
const { t } = useI18n();
const router = useRouter();

const props = defineProps<{
  school: School;
}>();

const emit = defineEmits(["schoolUpdated", "schoolDeleted"]);
const schoolUpdated = () => {
  emit("schoolUpdated");
};
const schoolDeleted = () => {
  emit("schoolDeleted");
};

const resetSelectedSchool = inject("resetSelectedSchool", () => {});

const showRssDialog = ref(false);
const { locale } = useI18n();

const schoolStore = useSchoolStore();

const SCHOOL = computed(() => props.school);

const { useSuccessToast, useErrorToast } = useFeedbackToast();

const UPDATE_SCHOOL = async () => {
  //sending entire school object makes api fail
  const school: SchoolDto = {
    id: props.school.id,
    name: props.school.name,
    districtId: props.school.districtId,
    menuId: props.school.menuId,
    visible: props.school.visible,
    urlName: props.school.urlName,
    imageUrl: props.school.imageUrl,
    image: props.school.image,
    customerId: props.school.customerId,
  };
  try {
    await schoolStore.UPDATE_SCHOOL(school);
    schoolUpdated();
    useSuccessToast(t("update_", { item: t("school") }));
  } catch (error) {
    useErrorToast(t("update_", { item: t("school") }));
  }
};

const DELETE_SCHOOL = async () => {
  if (!props.school.id) return;
  try {
    await schoolStore.DELETE_SCHOOL(props.school.id);
    schoolDeleted();
    useSuccessToast(t("delete_", { item: t("school") }));
    resetSelectedSchool();
    router.replace({ name: "SchoolView" });
  } catch (error) {
    useErrorToast(t("delete_", { item: t("school") }));
  }
};

const UPDATE_SCHOOL_LOADING = computed(
  () => schoolStore.$state.loading.UPDATE_SCHOOL,
);

const DELETE_SCHOOL_LOADING = computed(
  () => schoolStore.$state.loading.DELETE_SCHOOL,
);

const confirmDeleteSchool = () => {
  confirm.require({
    message: t("delete_confirm_", { item: SCHOOL.value.name }),
    header: t("delete_", { item: t("school") }),
    rejectLabel: t("cancel"),
    acceptLabel: t("delete"),
    acceptClass: "bg-red-700 hover:bg-red-600 ring-red-700 focus:ring-red-700",
    accept: () => {
      DELETE_SCHOOL();
    },
    reject: () => {},
  });
};
</script>

<template>
  <school-form
    :school="SCHOOL"
    @update:name="SCHOOL.name = $event"
    @update:selected-district-id="SCHOOL.districtId = $event"
    @update:selected-menu-id="SCHOOL.menuId = $event"
    @update:visible="SCHOOL.visible = $event"
    @update:customer-id="SCHOOL.customerId = $event"
  />

  <div v-if="SCHOOL.menuId" class="mt-5">
    <Button @click="showRssDialog = true">
      <div class="flex flex-row items-center justify-center gap-2">
        <span>{{ t("rss_feed") }}</span>
        <RssIcon class="h-5 w-5" />
      </div>
    </Button>
  </div>

  <Divider />

  <div class="mt-2 flex justify-end">
    <Button
      class="mr-5 flex justify-center bg-red-700 font-semibold"
      severity="danger"
      :loading="DELETE_SCHOOL_LOADING"
      @click="confirmDeleteSchool"
    >
      <div class="flex justify-center">
        <span>{{ t("delete") }}</span>
      </div>
    </Button>

    <Button
      class="flex justify-center font-semibold"
      :loading="UPDATE_SCHOOL_LOADING"
      @click="UPDATE_SCHOOL"
    >
      <div class="flex justify-center">
        <span>{{ t("save") }}</span>
      </div>
    </Button>
  </div>
  <Dialog v-model:visible="showRssDialog" modal closable dismissable-mask>
    <template #header>
      <span class="font-bold text-slate-800">{{ $t("rss_feed") }}</span>
    </template>
    <div class="flex flex-col gap-2">
      <span class="text-sm text-slate-600">
        {{ $t("rss_description") }}
      </span>
      <a
        :href="`${config.viteApiBaseUrl}/4/rss/day/${SCHOOL.id}?locale=${locale}`"
        target="_blank"
        class="text-cyan-700 hover:text-cyan-600"
      >
        {{ $t("rss_day") }}
      </a>
      <a
        :href="`${config.viteApiBaseUrl}/4/rss/week/${SCHOOL.id}?locale=${locale}`"
        target="_blank"
        class="text-cyan-700 hover:text-cyan-600"
      >
        {{ $t("rss_week") }}
      </a>
    </div>
  </Dialog>
</template>
