<script setup lang="ts">
import type { District } from "@/api/prisma-interfaces";
import LoadingSpinner from "@/components/shared/LoadingSpinner.vue";
import {
  GET_DISTRICTS_BY_PROVINCE_ID,
  useDistrictStore,
} from "@/stores/DistrictStore";
import { computed, onMounted, ref } from "vue";
import { useRoute, RouterLink } from "vue-router";
import { ChevronLeftIcon } from "@heroicons/vue/20/solid";
import { setTitle } from "@/utils/SetTitle";

const route = useRoute();
const districtStore = useDistrictStore();

let districts = ref<District[]>([]);
let province = ref("");

onMounted(async () => {
  const result = await districtStore[GET_DISTRICTS_BY_PROVINCE_ID](
    route.params.id as string,
  );
  districts.value = result?.districts || [];

  province.value = result?.province.name || "";

  setTitle(province.value);
});

const LOADING_DISTRICTS = computed(() => {
  return districtStore.$state.loading.GET_DISTRICTS_BY_PROVINCE_ID;
});
</script>

<template>
  <div class="flex justify-center">
    <div v-if="LOADING_DISTRICTS">
      <loading-spinner />
    </div>
    <div v-else>
      <router-link
        class="flex items-center text-lg hover:text-cyan-600"
        :to="'/'"
      >
        <ChevronLeftIcon class="h-8 w-8 fill-cyan-600" />
        {{ $t("province", 2) }}
      </router-link>
      <div class="my-3 border-b-4 border-amber-400 py-1 text-2xl font-bold">
        {{ province }}
      </div>
      <div v-if="districts.length">
        <div
          class="grid grid-cols-1 gap-5 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4"
        >
          <router-link
            v-for="d in districts"
            :key="d.id"
            class="rounded-md border border-slate-200 px-4 py-2 shadow-sm duration-75 ease-in-out hover:cursor-pointer hover:bg-slate-800 hover:text-white"
            :to="`/district/${d.id}`"
          >
            {{ d.name }}
          </router-link>
        </div>
      </div>
      <div v-else>{{ $t("no_districts_to_show") }}</div>
    </div>
  </div>
</template>
