import HttpClient, { ResponseType } from "../utils/HttpClient";
import {
  type Bulletin,
  type Menu,
  type WeekState,
} from "@/api/prisma-interfaces";
const MENUS_URL = "4/menu";

export interface MenuResponse {
  menus: Menu[];
}

export interface PublicMenuResponse {
  id: string;
  name: string;
  numberOfItems: number;
  WeekState: WeekState | null;
  Bulletin?: Bulletin | null;
  School: {
    id: string;
    name: string;
    urlName: string;
    image: { type: "Buffer"; data: Uint8Array } | null;
    Bulletin?: Bulletin | null;
    District: {
      id: string;
      name: string;
      urlName: string;
      image: { type: "Buffer"; data: Uint8Array } | null;
      Bulletin?: Bulletin | null;
    };
  };
}

export interface MenuDto
  extends Omit<Menu, "id" | "createdAt" | "updatedAt" | "oldId"> {
  id?: Menu["id"];
}

class MenuService {
  private httpClient: HttpClient;

  constructor(httpClient: HttpClient = new HttpClient()) {
    this.httpClient = httpClient;
  }

  async getMenus(
    take?: number,
    skip?: number,
  ): Promise<{ menus: Menu[]; total: number }> {
    const response = await this.httpClient.get<{
      menus: Menu[];
      total: number;
    }>(
      `/admin/${MENUS_URL}?take=${take ? take : "20"}${
        skip ? `&skip=${skip}` : ""
      }`,
    );
    return response;
  }

  async getMenusByDistrictId(districtId: string): Promise<Menu[]> {
    const response = await this.httpClient.get<Menu[]>(
      `/admin/${MENUS_URL}/district/${districtId}`,
    );
    return response;
  }

  async getMenuById(id: string): Promise<Menu> {
    const response = await this.httpClient.get<Menu>(
      `/admin/${MENUS_URL}/${id}`,
    );
    return response;
  }

  async getMenusBySchoolIds(ids: string[]): Promise<Menu[]> {
    const response = await this.httpClient.get<Menu[]>(
      `/admin/${MENUS_URL}/schools?ids=${ids}`,
    );
    return response;
  }

  async getMenuForPublic(
    schoolId: string,
    year: number,
    week: number,
  ): Promise<PublicMenuResponse> {
    const response = await this.httpClient.get<PublicMenuResponse>(
      `/4/menu/${schoolId}?year=${year}&week=${week}`,
    );
    return response;
  }

  async createMenu(menu: MenuDto): Promise<Menu> {
    const response = await this.httpClient.post<Menu>(`/admin/${MENUS_URL}`, {
      menu: {
        name: menu.name,
        numberOfItems: menu.numberOfItems,
      },
    });
    return response;
  }

  async updateMenu(data: { menu: MenuDto }): Promise<Menu> {
    const response = await this.httpClient.put<Menu>(`/admin/${MENUS_URL}`, {
      menu: {
        id: data.menu.id,
        name: data.menu.name,
        numberOfItems: data.menu.numberOfItems,
      },
    });
    return response;
  }

  async deleteMenu(id: string): Promise<void> {
    await this.httpClient.delete(`/admin/${MENUS_URL}/${id}`);
  }

  async getImportPreview(menuId: string, year: number): Promise<WeekState[]> {
    const response = await this.httpClient.get<WeekState[]>(
      `/admin/${MENUS_URL}/import/${menuId}?year=${year}`,
    );
    return response;
  }

  async importFromMenu(
    menuId: string,
    weekIds: string[],
    dayIds: string[],
  ): Promise<Menu> {
    const response = await this.httpClient.put<Menu>(
      `/admin/${MENUS_URL}/import/${menuId}`,
      {
        weekIds,
        dayIds,
      },
    );

    return response;
  }

  async importFromFile(
    menuId: string,
    file: File,
    encoding: string,
  ): Promise<Menu> {
    const formData = new FormData();
    formData.append("file", file);
    formData.append("encoding", encoding);
    const response = await this.httpClient.put<Menu>(
      `/admin/${MENUS_URL}/import-file/${menuId}`,
      formData,
    );
    return response;
  }

  async exportToFile(
    menuId: string,
    encoding: string,
    year: number,
  ): Promise<Blob> {
    const response = await this.httpClient.get<Blob>(
      `/admin/${MENUS_URL}/export/${menuId}?encoding=${encoding}&year=${year}`,
      ResponseType.Blob,
    );
    return response;
  }
}

export default new MenuService();
