<script setup lang="ts">
import type { Bulletin, WeekState } from "@/api/prisma-interfaces";
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/vue/20/solid";
import { PrinterIcon, RssIcon } from "@heroicons/vue/24/outline";
import {
  getDatesInWeek,
  getWeekDates,
  getWeekNumberByDate,
} from "@/utils/DateUtils";
import Button from "primevue/button";
import Dialog from "primevue/dialog";
import * as config from "@/config";
import { computed, onMounted, reactive, ref, watch } from "vue";
import { useRoute, RouterLink } from "vue-router";
import { buffToBase64 } from "@/utils/ImageUtils";
import { watchEffect } from "vue";
import { useMenuStore } from "@/stores/MenuStore";
import { useBulletinStore } from "@/stores/BulletinStore";
import { setTitle } from "@/utils/SetTitle";
import { useI18n } from "vue-i18n";
import MealAttributeBubble from "@/components/shared/MealAttributeBubble.vue";
import type { PublicMenuResponse } from "@/services/MenuService";
import LoadingSpinner from "@/components/shared/LoadingSpinner.vue";
import { printMenus } from "@/utils/PrintUtils";
import PublicBulletinView from "@/views/Public/PublicBulletinView.vue";

const WEEK_DAYS = ["monday", "tuesday", "wednesday", "thursday", "friday"];
const { t, locale } = useI18n();
const menuStore = useMenuStore();
const bulletinStore = useBulletinStore();

const route = useRoute();
let weekState = ref<WeekState>();
const menu = ref<PublicMenuResponse>();
const today = new Date();

let weekOffset = ref(0);
const week = computed(() => {
  const offsetDate = new Date(today);
  offsetDate.setDate(today.getDate() + 7 * weekOffset.value);
  return {
    week: getWeekNumberByDate(offsetDate),
    year: offsetDate.getFullYear(),
  };
});

onMounted(async () => {
  menu.value = await menuStore.GET_PUBLIC_MENU(
    route.query.school as string,
    week.value.year,
    week.value.week,
  );

  weekState.value = menu.value?.WeekState || undefined;
});

const findIndex = (date: Date) => {
  if (!weekState.value || !weekState.value.Days?.length) return -78;

  return weekState.value.Days.findIndex((d) => {
    const newDate = new Date(d.date);
    return (
      newDate.getFullYear() === date.getFullYear() &&
      newDate.getMonth() === date.getMonth() &&
      newDate.getDate() === date.getDate()
    );
  });
};

const showPreviousWeekMenu = async () => {
  weekOffset.value--;

  menu.value = await menuStore.GET_PUBLIC_MENU(
    route.query.school as string,
    week.value.year,
    week.value.week,
  );
  weekState.value = menu.value?.WeekState || undefined;
};

const showNextWeekMenu = async () => {
  weekOffset.value++;

  menu.value = await menuStore.GET_PUBLIC_MENU(
    route.query.school as string,
    week.value.year,
    week.value.week,
  );
  weekState.value = menu.value?.WeekState || undefined;
};

const bulletin = ref<Bulletin>();
const bulletins = ref<Bulletin[]>([]);

const isActive = ref(false);

let weekNumber = ref(getWeekNumberByDate(today));

const weekDays = ref<Date[]>([]);

watch(
  weekOffset,
  () => {
    weekDays.value = getDatesInWeek(week.value.week, week.value.year);
    weekNumber.value = getWeekNumberByDate(
      getWeekDates(week.value.week, week.value.year)[0],
    );
    updateBulletin();
    updateBulletins();
  },
  { immediate: true },
);

watchEffect(async () => {
  menu.value = await menuStore.GET_PUBLIC_MENU(
    route.query.school as string,
    week.value.year,
    week.value.week,
  );
  setTitle(menu.value?.School.name ?? "");
  updateBulletin();
  updateBulletins();
});

const MENU_LOADING = computed(() => {
  return menuStore.$state.loading.GET_PUBLIC_MENU;
});

async function updateBulletins() {
  const menuId = menu.value?.id;
  const schoolId = menu.value?.School.id;
  const districtId = menu.value?.School.District.id;
  const fetchedBulletins = await bulletinStore.GET_RELATED_BULLETINS_BY_IDS(
    menuId || "",
    schoolId || "",
    districtId || "",
  );

  const activeBulletins = fetchedBulletins?.filter((b) => bulletinIsActive(b));

  if (activeBulletins) {
    bulletins.value = activeBulletins;
  }
}

function updateBulletin() {
  if (menu.value?.Bulletin && bulletinIsActive(menu.value.Bulletin)) {
    bulletin.value = menu.value.Bulletin;
  } else if (
    menu.value?.School.Bulletin &&
    bulletinIsActive(menu.value.School.Bulletin)
  ) {
    bulletin.value = menu.value.School.Bulletin;
  } else if (
    menu.value?.School.District.Bulletin &&
    bulletinIsActive(menu.value.School.District.Bulletin)
  ) {
    bulletin.value = menu.value.School.District.Bulletin;
  }
  isActive.value = bulletinIsActive(bulletin.value);
}

function bulletinIsActive(bulletin?: Bulletin) {
  if (bulletin && !bulletin?.activate && !bulletin?.deactivate) {
    return true;
  }

  if (bulletin?.activate && !bulletin?.deactivate) {
    return (
      new Date(bulletin.activate).getTime() <=
      getWeekDates(week.value.week, week.value.year)[1].getTime()
    );
  }

  if (!bulletin?.activate && bulletin?.deactivate) {
    return (
      new Date(bulletin.deactivate).getTime() >=
      getWeekDates(week.value.week, week.value.year)[0].getTime()
    );
  }

  if (bulletin?.activate && bulletin?.deactivate) {
    return (
      new Date(bulletin.activate).getTime() <=
        getWeekDates(week.value.week, week.value.year)[1].getTime() &&
      new Date(bulletin.deactivate).getTime() >=
        getWeekDates(week.value.week, week.value.year)[0].getTime()
    );
  }
  return false;
}

const downloadMenuAsPdf = async (weeksToInclude = 1) => {
  await printMenus(
    route.query.school as string,
    weekOffset.value,
    weeksToInclude,
    today,
    t,
    locale.value,
    menuStore,
  );
};

const showRssDialog = ref(false);
const showPdfDialog = ref(false);
</script>
<template>
  <div class="flex justify-center">
    <div class="w-full max-w-[44rem] justify-center">
      <div class="flex flex-row items-center justify-between">
        <router-link
          :to="`/district/${menu?.School?.District?.id}`"
          class="flex items-center text-lg hover:text-cyan-600"
        >
          <ChevronLeftIcon class="h-8 w-8 fill-cyan-600" />
          {{ menu?.School?.District?.name }}
        </router-link>
        <div class="flex items-center gap-2">
          <button
            v-tooltip.right="$t('download_pdf')"
            :aria-label="$t('download_pdf')"
            @click="showPdfDialog = true"
          >
            <PrinterIcon class="h-6 w-6 text-cyan-600 hover:text-cyan-500" />
          </button>
          <button
            v-tooltip.right="$t('rss_feed')"
            :aria-label="$t('rss_feed')"
            @click="showRssDialog = true"
          >
            <RssIcon class="h-6 w-6 text-cyan-600 hover:text-cyan-500" />
          </button>
        </div>
      </div>
      <div id="menu-container">
        <div
          class="my-3 flex flex-row items-center justify-between border-b-4 border-amber-400 py-1 text-2xl font-bold"
        >
          <div class="schoolName flex-1">
            {{ menu?.School?.name }}
          </div>

          <div v-if="menu?.School?.image">
            <img
              :src="buffToBase64(menu?.School?.image.data)"
              class="h-12 w-12 rounded-full"
            />
          </div>
          <div v-else>
            <img
              v-if="menu?.School?.District?.image"
              :src="buffToBase64(menu?.School?.District?.image.data)"
              class="h-12 w-12 rounded-full"
            />
          </div>
        </div>
        <div class="text-2xl font-semibold" aria-live="polite">
          {{ $t("week") }} {{ week.week }}
        </div>

        <div v-if="MENU_LOADING" class="flex justify-center">
          <LoadingSpinner />
        </div>

        <div v-else class="mb-6 flex flex-col">
          <div
            v-for="d in weekDays"
            :key="d.getMilliseconds()"
            class="my-3 flex-row border-b border-slate-200"
          >
            <div class="mr-4">
              <div class="w-24 self-center text-lg font-semibold">
                {{ $t(WEEK_DAYS[d.getDay() - 1]) }}
              </div>
              <div class="text-sm text-gray-500">
                {{ d.toISOString().split("T")[0] }}
              </div>
            </div>
            <div v-if="findIndex(d) >= 0">
              <div
                v-if="weekState?.Days?.[findIndex(d)]?.cancelled"
                class="text-red-700"
              >
                {{ weekState?.Days[findIndex(d)]?.cancelled }}
              </div>
              <div v-else>
                <div
                  v-for="m in weekState?.Days?.[findIndex(d)]?.Meals"
                  :key="m.id"
                  class="row mb-2 flex items-center justify-between"
                >
                  <span>{{ m.name }}</span>

                  <div
                    v-if="m.mealAttributeIds"
                    class="flex flex-wrap justify-end"
                  >
                    <div
                      v-for="attribute in m.MealAttributes"
                      :key="attribute.id"
                    >
                      <div class="m-1 items-center">
                        <MealAttributeBubble
                          v-tooltip.top="
                            locale === 'sv'
                              ? attribute.sv || attribute.name
                              : attribute.en || attribute.name
                          "
                          small
                          :name="
                            locale === 'sv'
                              ? attribute.sv || attribute.name
                              : attribute.en || attribute.name
                          "
                          :img-buf="attribute.image?.data as Buffer"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div v-else class="text-gray-500">{{ $t("menu_missing") }}</div>
          </div>
        </div>

        <div v-if="WEEK_DAYS && bulletins && bulletins.length > 0">
          <PublicBulletinView
            v-for="b in bulletins"
            :key="b.id"
            :bulletin="b"
          />
        </div>

        <PublicBulletinView
          v-else-if="WEEK_DAYS && bulletin"
          :bulletin="bulletin"
        />
      </div>
      <div class="flex justify-between">
        <button
          class="flex items-center text-lg hover:text-cyan-600"
          @click="showPreviousWeekMenu"
        >
          <ChevronLeftIcon class="h-8 w-8 fill-cyan-600" />
          {{ $t("previous_week") }}
        </button>
        <button
          class="flex items-center text-lg hover:text-cyan-600"
          @click="showNextWeekMenu"
        >
          {{ $t("next_week") }}
          <ChevronRightIcon class="h-8 w-8 fill-cyan-600" />
        </button>
      </div>
    </div>
    <div class="sr-only" aria-live="polite">
      {{ $t("week") }} {{ weekNumber }}
    </div>
  </div>
  <Dialog v-model:visible="showRssDialog" modal closable dismissable-mask>
    <template #header>
      <span class="font-bold text-slate-800">{{ $t("rss_feed") }}</span>
    </template>
    <div class="flex flex-col gap-2">
      <span class="text-sm text-slate-600">
        {{ $t("rss_description") }}
      </span>
      <a
        :href="`${config.viteApiBaseUrl}/4/rss/day/${
          route.query.school as string
        }?locale=${locale}`"
        target="_blank"
        class="text-cyan-700 hover:text-cyan-600"
      >
        {{ $t("rss_day") }}
      </a>
      <a
        :href="`${config.viteApiBaseUrl}/4/rss/week/${
          route.query.school as string
        }?locale=${locale}`"
        target="_blank"
        class="text-cyan-700 hover:text-cyan-600"
      >
        {{ $t("rss_week") }}
      </a>
    </div>
  </Dialog>
  <Dialog v-model:visible="showPdfDialog" modal closable dismissable-mask>
    <template #header>
      <span class="font-bold text-slate-800">{{ $t("download_pdf") }}</span>
    </template>
    <div class="flex flex-col gap-2 pt-2">
      <Button @click="downloadMenuAsPdf(1)">
        {{ $t("download_current_week") }}
      </Button>
      <Button @click="downloadMenuAsPdf(2)">
        {{ $t("download_2_weeks") }}
      </Button>
      <Button @click="downloadMenuAsPdf(4)">
        {{ $t("download_4_weeks") }}
      </Button>
    </div>
  </Dialog>
</template>

<style scoped>
.schoolName {
  overflow-wrap: break-word;
  hyphens: auto;
}
</style>
