<script setup lang="ts">
import { ref, onMounted } from "vue";
import type { School } from "@/api/prisma-interfaces";
import { useSchoolStore } from "@/stores/SchoolStore";
import { useFeedbackToast } from "@/composables/useFeedbackToast";
import { ChevronLeftIcon } from "@heroicons/vue/20/solid";
import { useI18n } from "vue-i18n";
import LoadingSpinner from "@/components/shared/LoadingSpinner.vue";
import { RouterLink } from "vue-router";

const schoolStore = useSchoolStore();
const nearBySchools = ref<School[]>();
const nonNearbySchoolsMsg = ref("");
const { useErrorToast } = useFeedbackToast();
const { t } = useI18n();

const latitude = ref(0);
const longitude = ref(0);
const isLoading = ref(false);

const updatePosition = async (position: GeolocationPosition) => {
  latitude.value = position.coords.latitude;
  longitude.value = position.coords.longitude;

  try {
    nearBySchools.value = await schoolStore.GET_SCHOOLS_NEARBY(
      latitude.value,
      longitude.value,
    );

    if (!nearBySchools.value?.length) {
      nonNearbySchoolsMsg.value = t("no_nearby_schools_to_show");
    }
  } catch (error) {
    useErrorToast("Get schools nearby");
    console.error(error);
  } finally {
    isLoading.value = false;
  }
};

const handleError = (err: any) => {
  useErrorToast("Get current location");
  console.error(err);
};

onMounted(() => {
  if (!navigator.geolocation) {
    useErrorToast("Geolocation is not supported by this browser");
    console.error("Geolocation is not supported by this browser.");
    return;
  }

  isLoading.value = true;
  navigator.geolocation.getCurrentPosition(updatePosition, handleError);
});
</script>
<template>
  <div class="flex justify-center">
    <div>
      <button
        class="flex items-center text-lg hover:text-cyan-600"
        @click="() => $router.push('/')"
      >
        <ChevronLeftIcon class="h-8 w-8 fill-cyan-600" />
        {{ $t("province", 2) }}
      </button>

      <div
        class="my-3 w-fit border-b-4 border-amber-400 py-1 text-start text-xl font-bold"
      >
        {{ $t("nearby_schools") }}
      </div>
      <div v-if="isLoading">
        <loading-spinner />
      </div>
      <div v-else>
        <div v-if="nearBySchools?.length && !nonNearbySchoolsMsg">
          <div
            class="grid grid-cols-1 gap-5 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4"
          >
            <router-link
              v-for="s in nearBySchools"
              :key="s.id"
              class="rounded-md border border-slate-200 px-4 py-2 shadow-sm duration-75 ease-in-out hover:cursor-pointer hover:bg-slate-800 hover:text-white"
              :to="{
                path: `/menu/${s.menuId}`,
                query: {
                  school: s.id,
                },
              }"
            >
              {{ s.name }}
            </router-link>
          </div>
        </div>
        <div v-else>{{ nonNearbySchoolsMsg }}</div>
      </div>
    </div>
  </div>
</template>
