<script setup lang="ts">
import { useProvinceStore } from "@/stores/ProvinceStore";
import { onMounted, computed } from "vue";
import LoadingSpinner from "@/components/shared/LoadingSpinner.vue";
import { MapPinIcon } from "@heroicons/vue/24/outline";
import { RouterLink } from "vue-router";
import Message from "primevue/message";
import * as config from "@/config";

const provinceStore = useProvinceStore();

onMounted(() => {
  provinceStore.FETCH_PROVINCES_FOR_PUBLIC();
});

const provinces = computed(() => {
  return provinceStore.PROVINCES;
});

const LOADING_PROVINCES = computed(
  () => provinceStore.$state.loading.FETCH_PROVINCES,
);
</script>
<template>
  <Message
    v-if="config.showNewsString"
    :life="7000"
    :sticky="false"
    class="text-slate-800"
  >
    {{ $t("news_message") }}</Message
  >
  <div class="flex justify-center">
    <div v-if="LOADING_PROVINCES">
      <loading-spinner />
    </div>
    <div v-else>
      <button
        class="flex flex-row gap-1 py-2 font-semibold text-cyan-600 duration-75 ease-in-out hover:cursor-pointer hover:text-cyan-500"
        @click="() => $router.push('/nearby')"
      >
        <MapPinIcon class="w-5 stroke-2" />
        {{ $t("find_nearby") }}
      </button>
      <div
        class="my-3 w-full border-b-4 border-amber-400 py-1 text-2xl font-bold"
      >
        {{ $t("province", 2) }}
      </div>
      <div
        class="grid grid-cols-1 gap-5 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4"
      >
        <router-link
          v-for="p in provinces"
          :key="p.id"
          class="rounded-md border border-slate-200 px-4 py-2 shadow-sm duration-75 ease-in-out hover:cursor-pointer hover:bg-slate-800 hover:text-white"
          :to="`/province/${p.id}`"
        >
          {{ p.name }}
        </router-link>
      </div>
    </div>
  </div>
</template>
