import SchoolService, { type SchoolDto } from "@/services/SchoolService";
import {
  type District,
  type Menu,
  type School,
  type User,
} from "@/api/prisma-interfaces";
import { acceptHMRUpdate, defineStore } from "pinia";
import UserService from "@/services/UserService";
import { getErrorMessage } from "@/utils/ErrorHandler";

export interface SchoolState {
  schools: School[];
  loading: { [key: string]: boolean };
  count: number;
  error?: { [key: string]: any } | null;
}

export interface SchoolConnections {
  users?: User[];
  district?: District;
  menu?: Menu;
}

// Actions
export const FETCH_SCHOOLS = "FETCH_SCHOOLS";
export const UPDATE_SCHOOL = "UPDATE_SCHOOL";
export const UPDATE_SCHOOL_IMAGE = "UPDATE_SCHOOL_IMAGE";
export const UPDATE_SCHOOL_LOCATION = "UPDATE_SCHOOL_LOCATION";
export const DELETE_SCHOOL = "DELETE_SCHOOL";
export const CREATE_SCHOOL = "CREATE_SCHOOL";
export const GET_SCHOOL = "GET_SCHOOL";
export const GET_SCHOOLS_BY_DISTRICT_ID = "GET_SCHOOLS_BY_DISTRICT_ID";
export const GET_SCHOOLS_BY_DISTRICTS = "GET_SCHOOLS_BY_DISTRICTS";
export const CONNECTIONS = "CONNECTIONS";
export const GET_VISIBLE_SCHOOLS_BY_DISTRICT_ID =
  "GET_VISIBLE_SCHOOLS_BY_DISTRICT_ID";
export const GET_SCHOOLS_NEARBY = "GET_SCHOOLS_NEARBY";

// Getters
export const SCHOOLS = "SCHOOLS";
export const SCHOOLS_BY_DISTRICT_ID = "SCHOOLS_BY_DISTRICT_ID";

export const useSchoolStore = defineStore("school", {
  state: (): SchoolState => ({
    schools: [],
    loading: {},
    count: 0,
    error: null,
  }),
  actions: {
    async [FETCH_SCHOOLS](take?: number, skip?: number) {
      this.setLoading(FETCH_SCHOOLS, true);
      try {
        const page = await SchoolService.getSchools(take, skip);
        this.schools = page.schools;
        this.count = page.total;
        return page;
      } catch (error) {
        this.setError(FETCH_SCHOOLS, getErrorMessage(error));
      } finally {
        this.setLoading(FETCH_SCHOOLS, false);
      }
    },
    async [GET_SCHOOLS_NEARBY](latitude: number, longitude: number) {
      this.setLoading(GET_SCHOOLS_NEARBY, true);
      try {
        const schools = await SchoolService.getSchoolsNearby(
          latitude,
          longitude,
        );
        return schools;
      } catch (error) {
        this.setError(GET_SCHOOLS_NEARBY, getErrorMessage(error));
      } finally {
        this.setLoading(GET_SCHOOLS_NEARBY, false);
      }
    },
    async [GET_VISIBLE_SCHOOLS_BY_DISTRICT_ID](districtId: string) {
      this.setLoading(GET_VISIBLE_SCHOOLS_BY_DISTRICT_ID, true);
      try {
        const { schools, district, province } =
          await SchoolService.getVisibleSchoolsByDistrictId(districtId);
        return { schools, district, province };
      } catch (error) {
        this.setError(
          GET_VISIBLE_SCHOOLS_BY_DISTRICT_ID,
          getErrorMessage(error),
        );
      } finally {
        this.setLoading(GET_VISIBLE_SCHOOLS_BY_DISTRICT_ID, false);
      }
    },
    async [UPDATE_SCHOOL](school: SchoolDto) {
      this.setLoading(UPDATE_SCHOOL, true);
      try {
        const updatedSchool = await SchoolService.updateSchool(school);
        const index = this.schools.findIndex((s) => s.id === updatedSchool.id);
        if (index !== -1) {
          this.schools.splice(index, 1, updatedSchool);
        }
      } catch (error) {
        this.setError(UPDATE_SCHOOL, getErrorMessage(error));
      } finally {
        this.setLoading(UPDATE_SCHOOL, false);
      }
    },
    async [UPDATE_SCHOOL_IMAGE](schoolId: string, image: FormData) {
      this.setLoading(UPDATE_SCHOOL_IMAGE, true);
      try {
        const updatedSchool = await SchoolService.updateSchoolImage(
          schoolId,
          image,
        );
        const index = this.schools.findIndex((s) => s.id === schoolId);
        if (index >= 0) {
          this.schools.splice(index, 1, updatedSchool);
        }
        return updatedSchool.image;
      } catch (error) {
        this.setError(UPDATE_SCHOOL_IMAGE, getErrorMessage(error));
      } finally {
        this.setLoading(UPDATE_SCHOOL_IMAGE, false);
      }
    },
    async [UPDATE_SCHOOL_LOCATION](
      schoolId: string,
      latitude: number,
      longitude: number,
    ) {
      this.setLoading(UPDATE_SCHOOL_LOCATION, true);
      try {
        const updatedSchool = await SchoolService.updateSchoolLocation(
          schoolId,
          latitude,
          longitude,
        );
        const index = this.schools.findIndex((s) => s.id === schoolId);
        if (index >= 0) {
          this.schools.splice(index, 1, updatedSchool);
        }
      } catch (error) {
        this.setError(UPDATE_SCHOOL_LOCATION, getErrorMessage(error));
      } finally {
        this.setLoading(UPDATE_SCHOOL_LOCATION, false);
      }
    },
    async [DELETE_SCHOOL](schoolId: string) {
      this.setLoading(DELETE_SCHOOL, true);
      try {
        await SchoolService.deleteSchool(schoolId);
        const index = this.schools.findIndex((s) => s.id === schoolId);
        if (index !== -1) {
          this.schools.splice(index, 1);
        }
      } catch (error) {
        this.setError(DELETE_SCHOOL, getErrorMessage(error));
      } finally {
        this.setLoading(DELETE_SCHOOL, false);
      }
    },
    async [CREATE_SCHOOL](school: SchoolDto) {
      this.setLoading(CREATE_SCHOOL, true);
      try {
        const createdSchool = await SchoolService.createSchool(school);
        this.schools.unshift(createdSchool);
        return createdSchool;
      } catch (error) {
        this.setError(CREATE_SCHOOL, getErrorMessage(error));
      } finally {
        this.setLoading(CREATE_SCHOOL, false);
      }
    },
    async [GET_SCHOOL](schoolId: string) {
      this.setLoading(GET_SCHOOL, true);
      try {
        const school = await SchoolService.getSchoolById(schoolId);
        return school;
      } catch (error) {
        this.setError(GET_SCHOOL, getErrorMessage(error));
      } finally {
        this.setLoading(GET_SCHOOL, false);
      }
    },
    async [GET_SCHOOLS_BY_DISTRICT_ID](districtId: string) {
      this.setLoading(GET_SCHOOLS_BY_DISTRICT_ID, true);
      try {
        const schools = await SchoolService.getSchoolsByDistrictId(districtId);
        return schools;
      } catch (error) {
        this.setError(GET_SCHOOLS_BY_DISTRICT_ID, getErrorMessage(error));
      } finally {
        this.setLoading(GET_SCHOOLS_BY_DISTRICT_ID, false);
      }
    },
    setLoading(action: string, value: boolean) {
      if (value) {
        this.setError(action, null);
      }
      this.loading[action] = value;
    },
    setError(action: string, value: any) {
      this.error = { ...this.error, [action]: value };
      if (value) {
        throw value;
      }
    },
    async [GET_SCHOOLS_BY_DISTRICTS](districtIds: string[]) {
      this.setLoading(GET_SCHOOLS_BY_DISTRICTS, true);
      try {
        const schools = await SchoolService.getSchoolsByDistricts(districtIds);
        return schools;
      } catch (error) {
        this.setError(GET_SCHOOLS_BY_DISTRICTS, getErrorMessage(error));
      } finally {
        this.setLoading(GET_SCHOOLS_BY_DISTRICTS, false);
      }
    },
  },
  getters: {
    [SCHOOLS](state: SchoolState): School[] {
      return state.schools.sort((a, b) => a.name.localeCompare(b.name));
    },
    [SCHOOLS_BY_DISTRICT_ID](
      state: SchoolState,
    ): (districtId: string) => School[] {
      return (districtId: string) => {
        return state.schools.filter((s) => s.districtId === districtId);
      };
    },
    [CONNECTIONS](): (school: School) => Promise<SchoolConnections> {
      return async (school: School) => {
        const users = await UserService.getUsersBySchoolId(school.id);
        const district = school.District ? school.District : undefined;
        const menu = school.Menu ? school.Menu : undefined;

        return {
          users,
          district,
          menu,
        };
      };
    },
  },
});

// make sure to pass the right store definition, `useAuth` in this case.
if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useSchoolStore, import.meta.hot));
}
