import DistrictService, { type DistrictDto } from "@/services/DistrictService";
import { acceptHMRUpdate, defineStore } from "pinia";
import { useProvinceStore } from "./ProvinceStore";
import type {
  District,
  Menu,
  Province,
  School,
  User,
} from "@/api/prisma-interfaces";
import UserService from "@/services/UserService";
import { getErrorMessage } from "@/utils/ErrorHandler";

export interface DistrictState {
  districts: District[];
  loading: { [key: string]: boolean };
  count: number;
  error?: { [key: string]: any } | null;
}

export interface DistrictConnections {
  province?: Province;
  schools?: School[];
  menu?: Menu;
  users?: User[];
}

// Actions
export const FETCH_DISTRICTS = "FETCH_DISTRICTS";
export const UPDATE_DISTRICT = "UPDATE_DISTRICT";
export const UPDATE_DISTRICT_IMAGE = "UPDATE_DISTRICT_IMAGE";
export const DELETE_DISTRICT = "DELETE_DISTRICT";
export const CREATE_DISTRICT = "CREATE_DISTRICT";
export const GET_DISTRICT = "GET_DISTRICT";
export const GET_DISTRICTS_BY_PROVINCE_ID = "GET_DISTRICTS_BY_PROVINCE_ID";

// Getters
export const DISTRICTS = "DISTRICTS";
export const DISTRICTS_BY_PROVINCE_ID = "DISTRICTS_BY_PROVINCE_ID";
export const PROVINCES_BY_DISTRICT_ID = "PROVINCES_BY_DISTRICT_ID";
export const CONNECTIONS = "CONNECTIONS";

export const useDistrictStore = defineStore("district", {
  state: (): DistrictState => ({
    districts: [],
    loading: {},
    count: 0,
    error: null,
  }),
  actions: {
    async [FETCH_DISTRICTS](take?: number, skip?: number) {
      this.setLoading(FETCH_DISTRICTS, true);
      try {
        const page = await DistrictService.getDistricts(take, skip);
        this.districts = page.districts;
        this.count = page.total;
        return page;
      } catch (error) {
        this.setError(FETCH_DISTRICTS, getErrorMessage(error));
      } finally {
        this.setLoading(FETCH_DISTRICTS, false);
      }
    },
    async [UPDATE_DISTRICT](district: DistrictDto) {
      this.setLoading(UPDATE_DISTRICT, true);
      try {
        const updatedDistrict = await DistrictService.updateDistrict({
          id: district.id,
          name: district.name,
          imageUrl: district.imageUrl,
          provinceId: district.provinceId,
          menuId: district.menuId,
          image: district.image,
          bulletinId: district.bulletinId,
          customerId: district.customerId,
        });
        const index = this.districts.findIndex(
          (d) => d.id === updatedDistrict.id,
        );
        if (index !== -1) {
          this.districts.splice(index, 1, updatedDistrict);
        }
      } catch (error) {
        this.setError(UPDATE_DISTRICT, getErrorMessage(error));
      } finally {
        this.setLoading(UPDATE_DISTRICT, false);
      }
    },
    async [UPDATE_DISTRICT_IMAGE](districtId: string, image: FormData) {
      this.setLoading(UPDATE_DISTRICT_IMAGE, true);
      try {
        const updatedDistrict = await DistrictService.updateDistrictImage(
          districtId,
          image,
        );
        const index = this.districts.findIndex(
          (d) => d.id === updatedDistrict.id,
        );
        if (index !== -1) {
          this.districts.splice(index, 1, updatedDistrict);
        }
        return updatedDistrict.image;
      } catch (error) {
        this.setError(UPDATE_DISTRICT_IMAGE, getErrorMessage(error));
      } finally {
        this.setLoading(UPDATE_DISTRICT_IMAGE, false);
      }
    },
    async [DELETE_DISTRICT](districtId: string) {
      this.setLoading(DELETE_DISTRICT, true);
      try {
        await DistrictService.deleteDistrict(districtId);
        const index = this.districts.findIndex((d) => d.id === districtId);
        if (index !== -1) {
          this.districts.splice(index, 1);
        }
      } catch (error) {
        this.setError(DELETE_DISTRICT, getErrorMessage(error));
      } finally {
        this.setLoading(DELETE_DISTRICT, false);
      }
    },
    async [CREATE_DISTRICT](district: DistrictDto) {
      this.setLoading(CREATE_DISTRICT, true);
      try {
        const createdDistrict = await DistrictService.createDistrict(district);
        this.districts.unshift(createdDistrict);
        return createdDistrict;
      } catch (error) {
        this.setError(CREATE_DISTRICT, getErrorMessage(error));
      } finally {
        this.setLoading(CREATE_DISTRICT, false);
      }
    },
    async [GET_DISTRICT](districtId: string) {
      this.setLoading(GET_DISTRICT, true);
      try {
        const district = await DistrictService.getDistrict(districtId);
        return district;
      } catch (error) {
        this.setError(GET_DISTRICT, getErrorMessage(error));
      } finally {
        this.setLoading(GET_DISTRICT, false);
      }
    },
    setLoading(action: string, value: boolean) {
      if (value) {
        this.setError(action, null);
      }
      this.loading[action] = value;
    },
    setError(action: string, value: any) {
      this.error = { ...this.error, [action]: value };
      if (value) {
        throw value;
      }
    },
    async [GET_DISTRICTS_BY_PROVINCE_ID](provinceId: string) {
      this.setLoading(GET_DISTRICTS_BY_PROVINCE_ID, true);
      try {
        const { districts, province } =
          await DistrictService.getDistrictsByProvinceId(provinceId);
        return { districts, province };
      } catch (error) {
        this.setError(GET_DISTRICTS_BY_PROVINCE_ID, getErrorMessage(error));
      } finally {
        this.setLoading(GET_DISTRICTS_BY_PROVINCE_ID, false);
      }
    },
  },
  getters: {
    [DISTRICTS](state: DistrictState): District[] {
      return state.districts;
    },
    [PROVINCES_BY_DISTRICT_ID](
      state: DistrictState,
    ): (districtId: string) => Province[] {
      const provinceStore = useProvinceStore();
      return (districtId: string) => {
        const district = state.districts.find((d) => d.id === districtId);
        if (!district) return [];
        const province = provinceStore.provinces.find(
          (p) => p.id === district.provinceId,
        );
        return province ? [province] : [];
      };
    },
    [CONNECTIONS](): (district: District) => Promise<DistrictConnections> {
      return async (district: District) => {
        const provinces = district.Province;
        const schools = district.Schools;
        const menu = district.Menu ? district.Menu : undefined;
        const users = await UserService.getUsersByDistrictId(district.id);
        return {
          province: provinces,
          schools,
          menu,
          users,
        };
      };
    },
  },
});

// make sure to pass the right store definition, `useAuth` in this case.
if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useDistrictStore, import.meta.hot));
}
