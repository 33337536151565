<script setup lang="ts">
import InputText from "primevue/inputtext";
import Checkbox from "primevue/checkbox";
import Dropdown from "primevue/dropdown";
import InputSwitch from "primevue/inputswitch";
import SelectSearchField from "@/components/shared/SelectSearchField.vue";
import type { SchoolDto } from "@/services/SchoolService";
import { computed, onMounted, ref, watch } from "vue";
import { useMenuStore } from "@/stores/MenuStore";
import { useDistrictStore } from "@/stores/DistrictStore";
import { Role, type District, type Menu } from "@/api/prisma-interfaces";
import { useI18n } from "vue-i18n";
import { useUserStore } from "@/stores/UserStore";
import { useOrganisationStore } from "@/stores/OrganisationStore";
import { InformationCircleIcon } from "@heroicons/vue/24/outline";

interface SchoolForm {
  school: SchoolDto;
  create?: boolean;
}

const { t } = useI18n();
onMounted(async () => {
  menuStore.FETCH_MENUS();
  districtStore.FETCH_DISTRICTS();

  if (props.school.districtId) {
    selectedDistrict.value = await districtStore.GET_DISTRICT(
      props.school.districtId,
    );
  }

  if (props.school.menuId)
    selectedMenu.value = await menuStore.GET_MENU(props.school.menuId);
});

const menuStore = useMenuStore();
const districtStore = useDistrictStore();
const userStore = useUserStore();
const organisationStore = useOrganisationStore();

const props = defineProps<SchoolForm>();

const visible = computed({
  get: () => props.school.visible,
  set: (value: boolean) => emit("update:visible", value),
});

const selectedDistrict = ref<District>();
const selectedMenu = ref<Menu>();
const selectedSchool = ref<{ id: string; name: string; schoolCode: string }>();
const newSchool = ref(false);

const emit = defineEmits<{
  (event: "update:name", id: string): void;
  (event: "update:selectedDistrictId", id: string): void;
  (event: "update:selectedMenuId", id: string): void;
  (event: "update:visible", value: boolean): void;
  (event: "update:customerId", id: string | null): void;
  (event: "update:istSchoolId", id: string | null): void;
}>();

watch(
  () => props.school,
  async (school) => {
    if (school.menuId) {
      selectedMenu.value = await menuStore.GET_MENU(school.menuId);
    } else {
      selectedMenu.value = undefined;
    }

    if (school.districtId) {
      selectedDistrict.value = await districtStore.GET_DISTRICT(
        school.districtId,
      );
    } else {
      selectedDistrict.value = undefined;
    }
  },
);

watch(
  () => selectedDistrict.value,
  (district) => {
    if (district) {
      emit("update:selectedDistrictId", district.id);
    }
    if (district?.customerId && props.create)
      organisationStore.FETCH_IST_SCHOOLS(district?.customerId);
  },
);
</script>

<template>
  <form>
    <div v-if="create">
      <span class="block pl-2 text-sm font-semibold"
        >{{ $t("district") }} *</span
      >
      <select-search-field
        v-model="selectedDistrict"
        :search-items="districtStore.DISTRICTS"
        item-type="district"
        class="w-full"
        @update:model-value="
          () => {
            if (selectedDistrict?.id) {
              emit('update:selectedDistrictId', selectedDistrict.id);
            }
          }
        "
      />
      <div v-if="selectedDistrict">
        <div class="mt-5 flex items-center">
          <input-switch v-model="newSchool" />
          <label for="newSchool" class="ml-3 text-sm font-semibold">{{
            newSchool ? t("add_new_school") : t("select_existing_school")
          }}</label>
        </div>
        <div v-if="newSchool" class="mt-5">
          <span class="block pl-2 text-sm font-semibold"
            >{{ t("school") }} {{ t("name") }} *
          </span>
          <input-text
            :model-value="school.name"
            :placeholder="$t('name')"
            class="w-full border-0 ring-slate-300"
            @update:model-value="$emit('update:name', $event || '')"
          />
        </div>

        <div v-else class="mt-5">
          <span class="block pl-2 text-sm font-semibold"
            >{{ t("school") }} *
          </span>
          <Dropdown
            v-model="selectedSchool"
            :options="organisationStore.IST_SCHOOLS"
            option-label="name"
            class="p-autocomplete h-full w-full"
            @update:model-value="
              () => {
                if (selectedSchool?.id) {
                  emit('update:name', selectedSchool.name);
                  emit('update:istSchoolId', selectedSchool.id);
                }
              }
            "
          />
        </div>
      </div>
    </div>

    <div v-else>
      <span class="block pl-2 text-sm font-semibold"
        >{{ t("school") }} {{ t("name") }} *
      </span>
      <input-text
        :model-value="school.name"
        :placeholder="$t('name')"
        class="w-full border-0 ring-slate-300"
        @update:model-value="$emit('update:name', $event || '')"
      />

      <div class="mt-5">
        <span class="block pl-2 text-sm font-semibold"
          >{{ $t("district") }} *</span
        >
        <select-search-field
          v-model="selectedDistrict"
          :search-items="districtStore.DISTRICTS"
          item-type="district"
          class="w-full"
          @update:model-value="
            () => {
              if (selectedDistrict?.id) {
                emit('update:selectedDistrictId', selectedDistrict.id);
              }
            }
          "
        />
      </div>
    </div>

    <div class="mt-5">
      <span class="block pl-2 text-sm font-semibold">{{ t("menu") }} *</span>
      <select-search-field
        v-model="selectedMenu"
        :search-items="menuStore.MENUS"
        item-type="menu"
        class="w-full"
        @update:model-value="
          () => {
            if (selectedMenu?.id) {
              emit('update:selectedMenuId', selectedMenu.id);
            }
          }
        "
      />
    </div>

    <div class="mt-5 flex items-center">
      <Checkbox v-model="visible" :binary="true" />
      <label for="visible" class="ml-3 text-sm">{{
        t("visible_for_public")
      }}</label>
    </div>

    <div v-if="userStore.currentUser?.role === Role.superAdmin" class="mt-5">
      <span class="block pl-2 text-sm font-semibold"
        >{{ $t("customer_id") }}
      </span>

      <div class="flex flex-row items-center justify-start gap-1">
        <InformationCircleIcon class="h-4 w-4" />
        <span class="block text-xs text-slate-800">{{
          $t("school_customer_id_info")
        }}</span>
      </div>
      <input-text
        :model-value="school.customerId"
        :placeholder="$t('customer_id')"
        class="w-full border-0 ring-slate-300"
        @update:model-value="$emit('update:customerId', $event || null)"
      />
    </div>
  </form>
</template>
